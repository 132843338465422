import React from "react"
import {
  // FaFacebookSquare,
  // FaInstagramSquare,
  // FaPinterestSquare,
  // FaTwitterSquare,
  FaFacebook,
  FaInstagram,
  FaPinterest,
  FaTwitter
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaFacebook className="social-icon"></FaFacebook>,
    url: " https://www.facebook.com/teammp2it/",
  },
  {
    id: 2,
    icon: <FaInstagram className="social-icon"></FaInstagram>,
    url: "https://www.instagram.com/mp2_it",
  },
  {
    id: 3,
    icon: <FaPinterest className="social-icon"></FaPinterest>,
    url: "https://in.pinterest.com/teammp2it",
  },
  {
    id: 4,
    icon: <FaTwitter   className="social-icon"></FaTwitter>,
    url: "https://twitter.com/mp2_it",
  },
]

const links=data.map(link=>{
   return(
     <li key={link.id}>
       <a href={link.url} className="social-link" target="_blank">
         {link.icon}
       </a>
     </li>
   )
})

export default ({styleClass})=>{
  return(
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}