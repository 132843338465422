import React from "react"
import logo from "../assets/images/logo.png"
import { FaAlignRight } from "react-icons/fa"
import PageLinks from "../constants/Links"
import { Link } from "gatsby"
const Navbar = ({toggleSidebar,cssclass}) => {

  
  return <nav className="navbar" style={{paddingTop:"10px"}}>
    <div className="nav-center">
      <div className="nav-header">
        <Link to="/"><img  src={logo} alt="mp2it logo" style={{width:"70px",marginTop:"10px"}}/></Link>
        <button type="button" className="toggle-btn" onClick={toggleSidebar}>
          <FaAlignRight></FaAlignRight>
        </button>
      </div>

      <PageLinks styleClass={cssclass}></PageLinks>
    </div>
  </nav>
}

export default Navbar
