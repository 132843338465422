import React from "react"
import { Link } from "gatsby"
import Layout2 from "../components/Layout2"
import {FaCheckCircle} from "react-icons/fa"

const thankyou = () => {
  return <Layout2>


    <article className="about-text">
   
    <main className="error-page">
    
    <div className="error-container error500">
    <FaCheckCircle style={{fontSize: "120px",
    color: "#23866a",marginBottom:"15px"}}></FaCheckCircle>
    <h2 >Thank You!</h2>
    <h4>We Will Get Back You Shortly...</h4>
      <Link to="/" className="btn">back home</Link>
  
    </div>

  </main>
    </article>
    
  </Layout2>
}

export default thankyou
