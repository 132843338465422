import React, { useState } from "react";
import { Link} from "gatsby"
import { useLocation } from "@reach/router";

// const [myclass,setmyclass]=useState("");
const Links = ({styleClass}) =>{

  // const pathname = window.location.pathname;
  const location = useLocation();
  const pathname =location.pathname;

  const [activeLink,setactiveLink]=useState("active-link");
  

  const data = [
    {
      id: 1,
      text: "home",
      url: "/",
    },
    {
      id: 2,
      text: "about",
      url: "/about/",
    },
    {
      id: 3,
      text: "products",
      url: "/products/",
    },
    // {
    //   id: 4,
    //   text: "blogs",
    //   url: "/blogs/",
    // },
    {
      id: 5,
      text: "contact",
      url: "/contact/",
    },
  ]

  const tempLinks=data.map(link =>{

    if(pathname===link.url && link.id===5)
  {
    return(
      <li key={link.id}>
        <Link className={activeLink} to={link.url} >{link.text}</Link>
      </li>
    )
  }
  else if(pathname===link.url && link.id===2) {
    return(
      <li key={link.id}>
        <Link className={activeLink} to={link.url} >{link.text}</Link>
      </li>
    )
  }
  else if(pathname===link.url && link.id===3) {
    return(
      <li key={link.id}>
        <Link className={activeLink} to={link.url} >{link.text}</Link>
      </li>
    )
  }
  else if(pathname===link.url && link.id===1) {
    return(
      <li key={link.id}>
        <Link className={activeLink,"active-link2" } to={link.url} >{link.text}</Link>
      </li>
    )
  }
  else{
    return(
      <li key={link.id}>
        <Link to={link.url} >{link.text}</Link>
      </li>
    )
  }
    
  })
  return(
      <ul className={`page-links ${styleClass ? styleClass : ""}`}>
        {tempLinks}
      </ul>
  )
}

export default Links